import * as React from 'react';
import { IProductDaViewProps } from './product-da';
import '@google/model-viewer';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// eslint-disable-next-line @typescript-eslint/no-require-imports
var QRCode = require('qrcode.react');
import { useMatchMedia } from './useMatchMedia';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'model-viewer': ModelViewerJSX & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface ModelViewerJSX {
    src: string;
    ar: string;
    loading: string;
    poster: string;
}

export default (props: IProductDaViewProps) => {
    const { androidUrl, iosUrl, thumbnailUrl, url } = props;
    const isDesktopResolution = useMatchMedia('(min-width:1025px)', true);
    const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
    console.log('Android', androidUrl);
    console.log('Android Length', androidUrl.length);
    console.log('Ios', iosUrl);
    console.log('Ios Length', iosUrl.length);
    console.log('Thumbnail', thumbnailUrl);
    console.log('Thumbnail Length', thumbnailUrl.length);
    return (
        <model-viewer
            ar=''
            ar-modes='scene-viewer webxr quick-look'
            ar-scale='fixed'
            src={androidUrl}
            ios-src={iosUrl}
            background-color='#9FB8AD'
            shadow-intensity='1'
            magic-leap
            auto-rotate
            camera-controls
            loading='lazy'
            poster={thumbnailUrl}
            style={{ backgroundColor: '#eee', width: '600px', height: '600px', display: 'flex', maxWidth: '100%' }}
        >
            {isDesktopResolution && (
                <Popup
                    trigger={
                        <button
                            className='button'
                            style={{
                                position: 'absolute',
                                top: '88%',
                                margin: '0',
                                left: '37%',
                                borderRadius: '25px',
                                border: 'solid 1px #b9b9b9',
                                fontSize: '12px',
                                padding: '8px 8px 8px 5px',
                                color: '#4285f4',
                                fontWeight: 700,
                                width: '170px'
                            }}
                        >
                            <div style={{ float: 'left', padding: '5px' }}>
                                <svg width='27px' height='27px' viewBox='0 0 512 512' id='icons' xmlns='http://www.w3.org/2000/svg'>
                                    <polyline
                                        points='201.14 64 256 32 310.86 64'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='256'
                                        y1='32'
                                        x2='256'
                                        y2='112'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='310.86 448 256 480 201.14 448'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='256'
                                        y1='480'
                                        x2='256'
                                        y2='400'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='64 207.51 64 144 117.15 112.49'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='64'
                                        y1='144'
                                        x2='131.29'
                                        y2='184'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='448 304.49 448 368 394.85 399.51'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='448'
                                        y1='368'
                                        x2='380.71'
                                        y2='328'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='117.15 400 64 368 64 304.49'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='64'
                                        y1='368'
                                        x2='130.64'
                                        y2='328'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='394.85 112.49 448 144 448 207.51'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='448'
                                        y1='144'
                                        x2='380.71'
                                        y2='184'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <polyline
                                        points='256 320 256 256 310.86 224'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                    <line
                                        x1='256'
                                        y1='256'
                                        x2='201.14'
                                        y2='224'
                                        fill='none'
                                        stroke='#000'
                                        strokeLinecap='round'
                                        strokeMiterlimit='10'
                                        strokeWidth='32'
                                    />
                                </svg>
                            </div>
                            <div style={{ marginTop: '11px' }}>
                                <span>View in your space</span>
                            </div>
                        </button>
                    }
                    {...{ overlayStyle }}
                    position='right center'
                    modal
                    nested
                >
                    {(close: React.MouseEventHandler<HTMLButtonElement> | undefined) => (
                        <div
                            className='modal'
                            style={{
                                fontSize: '12px',
                                border: '10px solid',
                                padding: '10px',
                                width: '350px',
                                height: '350px',
                                backgroundColor: '#ffffff',
                                borderImageSource: 'linear-gradient(to left, #8f8ae1, #70ebe7)',
                                borderWidth: '5px',
                                borderImageSlice: 1
                            }}
                        >
                            <span
                                className='close'
                                onClick={close}
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    padding: '2px 5px',
                                    right: '13px',
                                    top: '11px',
                                    fontSize: '30px',
                                    background: '#ffffff',
                                    fontFamily: 'monospace'
                                }}
                            >
                                x
                            </span>
                            <div className='content'>
                                <div style={{ marginBottom: '35px', marginTop: '20px' }}>
                                    <p
                                        style={{
                                            fontFamily: 'monospace',
                                            fontSize: 'x-large',
                                            paddingLeft: '30px',
                                            paddingRight: '30px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Scan this QR Code to see the product using Augmented Reality
                                    </p>
                                </div>
                                <div>
                                    <QRCode
                                        value={url.length !== 0 ? url : 'Unable to get the 3D model'}
                                        fgColor='#4872c7'
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'block',
                                            width: '50%',
                                            marginRight: 'auto',
                                            height: '50%',
                                            fgColor: '#3236a8'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            )}
        </model-viewer>
    );
};
